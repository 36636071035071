@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

#root {
  font-family: 'Lato', sans-serif;
  color: #2a3e53;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.prophet-table-header {
  background: #2a3e53;
  color: white;
}
