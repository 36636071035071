/* Prefix all classes with .upload- */

.upload-dropZone {
    height: 200px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border-style: dashed;
    border-color: #2a3e53;
}
