$prophet-red: #f73048;

$primary: $prophet-red;

.btn-primary {
    color: whitesmoke !important
}

.btn-outline-primary {
    &:hover {
        color: whitesmoke !important
    }
}

@import '~bootstrap/scss/bootstrap.scss';
